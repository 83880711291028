/* Content pages */

.entry-thumbnail,
.entry-content {
  /* Entry text/summaries should wrap naturally */
  white-space: pre-line;
}

.thumbnail {
  background-color: var(--background-thumbnail);
  padding: 0 5px;
  position: relative;
  margin-bottom: 0.8em;
}

.thumbnail-wrapper {
  position: absolute;
}

.thumbnail-wrapper > div,
.thumbnail-wrapper > a > div {
  border: 1px solid var(--primary-highlight);
  background-color: var(--background-thumbnail);
  font-size: 0.98em;
  line-height: 1.25;
  position: absolute;
}

.thumbnail,
.thumbnail-wrapper > div,
.thumbnail-wrapper > a > div {
  border-radius: 0.6em;
  padding: 0.4em;
}

.person-thumbnail {
  background-color: var(--background-thumbnail);
  color: var(--text);
  font-size: 1.2em;
  display: inline-block;
  width: calc(100% - 2 * 0.4em);
}

.person-thumbnail h3 {
  margin-bottom: 0;
  margin-right: 2em;
}

.person-thumbnail-title {
  font-weight: bold;
  margin: 0 0 0.4em;
}

.person-thumbnail-wrapper .person-thumbnail-link {
  overflow: hidden;
  position: relative;
  font-style: normal;
}

.person-thumbnail .person-metadata-summary {
  font-style: italic;
  font-size: 0.9em;
  margin-top: 0.3em;
}

.person-thumbnail .person-metadata-row {
  display: block;
}

.thumbnail-base-names ul {
  margin: 0;
}

.thumbnail-base-names li {
  margin-right: 2em;
  float: left;
}

.thumbnail-base-names a {
  text-decoration: none;
}

.thumbnail:not(.thumbnail-static) :not(.thumbnail-link) {
  cursor: initial;
}

.thumbnail:not(.thumbnail-static) a:not(.thumbnail-link) {
  cursor: pointer;
}

.person-country {
  font-size: 0.6em;
  font-style: italic;
  font-weight: normal;
}

.entry-title {
  font-style: italic;
  margin-top: -0.4em;
}

.entry-person-link .person-thumbnail-link {
  z-index: 1000;
  max-width: 20em;
}

.entry-person-link-text {
  color: var(--primary);
  cursor: pointer;
}

.statistic:not(.person-metadata-summary .statistic) {
  margin-right: 0.5em;
  white-space: nowrap;
}

.statistic-link-current {
  font-weight: bold;
}

.day-plus-n {
  margin-left: 0.1em;
  font-size: 0.7em;
  vertical-align: super;
}
