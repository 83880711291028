.app,
.login {
  padding: 8px;
  padding-bottom: 8px;
  font-family: Palatino, "Palatino Linotype", "Book Antiqua", serif;
  line-height: 1.6;
}

h2 {
  margin-top: 0;
  margin-bottom: -0.3em;
}

h3,
h4 {
  margin: 0;
  margin-bottom: 0.3em;
}


/** Various kinds of links */

a {
  color: var(--primary-highlight);
}

a:visited,
a:active {
  color: var(--primary);
}

.statistic a {
  text-decoration: none;
  color: var(--primary);
}

.thumbnail-list > a {
  text-decoration: none;
  color: var(--text);
}

.toggled-nav-bar a {
  display: contents;
  text-decoration: none;
}

.sync-logo a {
  text-decoration: none;
  color: var(--text);
}

.clickable {
  cursor: pointer;
  color: var(--primary)
}

.indented {
  margin-left: 1.5em;
  font-size: 90%;
  margin-bottom: 1em;
}


/* Single-purpose elements */

.warning {
  font-style: italic;
}

.error {
  color: var(--error);
  text-align: center;
  white-space: pre-line;
  margin-top: 20px;
}

.error-clickable {
  cursor: pointer;
}

.tip {
  font-style: italic;
  font-size: 0.9em;
  white-space: pre-line;
}

.tip .sample {
  font-style: normal;
  font-family: monospace;
}

.error .tip {
  margin-top: 0.3em;
}

.error svg {
  font-size: 4em;
  color: var(--error);
}

.error h3 {
  margin: 0.1em;
}

.error-new-data,
.error-new-data svg,
.nav-bar-button-img svg {
  color: var(--primary);
}

.clear-float {
  clear: both;
}

/* Explore help page */

.explore-help p {
  margin-block-start: 0;
}

.explore-help h3 {
  margin-top: 0.7em;
}

.explore-help-text a {
  text-decoration: none;
}

.explore-help-section strong {
    font-weight: bolder;
    text-decoration: underline;
}

.explore-help-section table {
    border-spacing: 0;
}

tr.separator td {
    border-top: 1px solid lightgrey;
}

.explore-help-tip {
  cursor: help;
}

.explore-help svg:not(.fa-minus) {
  color: var(--primary)
}

.explore-help table td {
  padding: 3px;
  font-style: italic;
  font-size: 90%;
  margin-left: 1em;
}

.explore-help-section {
  transition: max-height 1s;
  max-height: 85em;
  overflow: auto;
}

.explore-help-section-hidden {
  max-height: 0;
  transition: max-height 0.5s;
}

/* Login page */

.login > img {
  height: 4em;
  margin-bottom: 0.5em;
}

.login {
  text-align: center;
}

.login h3 {
  font-style: italic;
}

.login .testing-skip-auth {
  margin-top: 1em;
  font-size: small;
  font-style: italic;
}


/** Special rules for the About pages */
#footer {
  margin-top: 0.5em;
  font-size: 0.8em;
}

.about-page p {
  text-align: justify;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 0;
}

#footer a {
  text-decoration: none;
}

#breadcrumbs {
  margin-bottom: 1em;
}

#breadcrumbs img {
  height: 1em;
}

#breadcrumbs svg {
  color: var(--primary);
  margin-left: 0.5em;
  margin-right: 0.5em;
}

#breadcrumbs a {
  text-decoration: none;
}

.about-page h3 {
  color: var(--primary);
  margin-top: 1.5em;
}

.about-page ul ul {
  font-style: italic;
}

/* Graphs */

.graph,
.graph img,
.graph svg {
  width: 100%;
}

.wordcloud {
  width: 100%;
}

/* Floating Picker selectors */

.floating-query-results {
  position: absolute;
  left: 2em;
  margin-top: -2px;
  background-color: var(--background-page);
  border: 1px solid var(--primary-highlight);
  border-top: none;
  border-radius: 0.4em;
}

#picker-search-term {
  width: 15em;
}

.floating-query-results div.clickable {
  padding: 0.1em 0.4em;
}

.floating-query-results div.clickable:first-child {
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}

.floating-query-results div.clickable:last-child {
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
}

.floating-query-results div.clickable.hovered {
  background-color: var(--background-thumbnail);
}