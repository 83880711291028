/** Various special kinds of icons */

h3 svg {
    color: var(--primary);
}

.loading svg {
  /* standard loading */
  color: var(--primary);
  font-size: 3em;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}

svg.loading {
    color: var(--primary);
}

.loading h3 {
  margin-top: 1em;
  color: var(--primary);
  text-align: center;
}

svg.external-link {
  font-size: 0.6em !important;
  vertical-align: text-top;
  margin-right: 0;
  margin-left: 0.25em;
}

.entry-write-status {
  color: var(--primary);
  margin-left: 0.2em;
}

.entry-on-day a {
  text-decoration: none;
}

#entry-write-header {
  display: flex;
  margin-left: 0.75em;
  margin-right: 0.75em;
  text-align: center;
}

#entry-write-header > div {
    width: 100%;
}

#entry-write-header a {
  height: 1.75em;
}

svg.fa-triangle-exclamation, svg.fa-ban {
  color: var(--error)
}

a.entry-write-new,
a.entry-write-new:visited {
  font-size: 1.25em;
  right: 0.3em;
  top: 0;
  text-decoration: none;
}

.entry-write svg {
  margin-right: 0.15em;
}

.entry-write-descriptor svg {
  float: right;
  padding-top: 0.2em;
}

.entry-write-metadata svg {
  margin-left: 0.5em;
}

.settings-thumbnail-list svg {
  /* admin icons */
  color: var(--primary);
  border-radius: 0.5em;
  margin-right: 0.4em;
}

.entry-on-day .toggle-summary {
  color: var(--primary);
  cursor: pointer;
}

.separator-summary {
    display: block;
    border-radius: 1em;
    height: 0.2em;
    width: 7em;
    margin-right: 0.65em;
    margin-bottom: 0.1em;
    background-color: var(--disabled);
}

.separator-entry {
    display: block;
    border-radius: 1em;
    height: 0.2em;
    width: 20em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.1em;
    background-color: var(--disabled);
}

div.entry-content-summary-separator svg {
  /* '+' icon for expanding entry content summaries */
  position: relative;
  margin-left: 0.2em;
  top: -0.5em;
  float: right;
  cursor: pointer;
  color: var(--primary);
}

.page > .loading svg {
  /* "Loading site page" spinner */
  font-size: 4em;
}

#root > .loading svg,
.app > .loading svg {
  /* Top-level "retrieving auth details" spinner */
  font-size: 5em;
}

.tip.error svg {
  font-size: medium;
}

#root > .loading,
.app > .loading,
.page > .loading {
  /* Centre both of those whole-page spinners */
  position: relative;
  top: 100px;
}
