/* Bonus (hacky) rules for Opera.
 * Should be removed if/when this is done automatically by the browser.
 */
.opera input,
.opera textarea {
  background-color: var(--background-highlighted);
  color: var(--background-app);
}


/* Default (i.e. mobile device) nav-bar layout, to be overridden below */

@media (width >= 0) {
  body {
    margin: 0;
    margin-bottom: 17vw;
  }

  #nav-bar-wrapper {
    height: 17vw;
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  #nav-and-notification-bar-wrapper {
    height: calc(100% - 17vw);
    bottom: calc(17vw + 1px);
  }

  #user-nav-bar-toggle-icon {
    position: absolute;
    right: 0;
  }

  .toggled-nav-bar.toggled {
    max-height: 100%;
  }

  #nav-bar-wrapper,
  #nav-notification-bar,
  .toggled-nav-bar {
    background-color: var(--background-thumbnail);
    border-top: 1px solid var(--primary-highlight);
  }

  #nav-notification-bar {
    position: relative;
    bottom: -1px;
  }

  .nav-bar-button {
    font-size: 13vw;
  }

  .toggled-nav-bar .nav-bar-button-text {
    font-size: 0.45em;
  }

  #ubiquitous-nav-bar .nav-bar-button-text {
    font-size: 3.7vw;
  }

  .nav-bar-button svg:not(.nav-bar-button-overlay) {
    height: 10.3vw;
  }

  #nav-bar {
    max-width: calc(600px + 2 * 8px - 17vw);
    padding-right: 17vw; /* profile image (in padding space) is square, so this matches bar height */
  }

  .app {
    min-height: calc(100vh - 17vw - 16px);
  }

  .login {
    margin-top: 10px;
  }

  #entry-write-header svg,
  #entry-write-header > div {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
}


/* When the toggled nav bars aren't at the edge of the screen. */

@media (width >= 616px) {
  .toggled-nav-bar,
  #nav-notification-bar {
    border-left: 1px solid var(--primary-highlight);
    border-right: 1px solid var(--primary-highlight);
  }

  /* Need to give space for the 1px borders */
  #nav-notification-bar {
    width: calc(100% - 2px);
  }
}


/* Stop the menu buttons getting too big if the screen is medium size. */

@media (width >= 530px) {
  body {
    /* Leave enough space at the bottom for the #nav-bar-wrapper */
    margin-bottom: 90px;
  }

  #nav-bar-wrapper {
    height: 90px;
  }

  #nav-and-notification-bar-wrapper {
    height: calc(100% - 90px);
    bottom: 91px;
  }

  #user-nav-bar-toggle-icon {
    right: 0;
  }

  #nav-bar {
    font-size: 70px;
    max-width: calc(600px + 2 * 8px - 90px);
    padding-right: 90px;
  }

.nav-bar-button {
    font-size: 70px;
  }

  #ubiquitous-nav-bar .nav-bar-button-text {
    font-size: 0.3em;
  }

  .nav-bar-button svg:not(.nav-bar-button-overlay) {
    height: 55px;
  }

  .app {
    min-height: calc(100vh - 106px);
  }
}


/* Put the app content in the centre once the screen's wide enough */

@media (width >= 650px) {
  .app,
  .login {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    background-color: var(--background-page);
  }

  .login {
    padding-top: 10px;
    border-radius: 0.6em;
  }

  /* Put entry thumbnails right next to the link they elucidate */
  .entry-person-link {
    display: inline-block;
  }

  .entry-write form {
    width: 100%;
  }

  /* .entry-write textarea.entry {
    height: 5em;
  } */
}

/** Move the NavBar to the top of the screen */
@media (width >= 99999px) {
  body {
    margin-bottom: 0;
    margin-top: 90px;
  }

  #nav-bar-wrapper {
    top: 0;
    bottom: auto;
  }

  #nav-and-notification-bar-wrapper {
    flex-direction: column;
    top: 89px;
  }

  .toggled-nav-bar {
    flex-flow: column-reverse;
    margin-top: 0;
    top: 0;
  }

  #nav-bar-wrapper, #nav-notification-bar, .toggled-nav-bar {
    border-bottom: 1px solid var(--primary-highlight);
    border-top: 0;
  }
}