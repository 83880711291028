
.search-type-icons {
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.search-type-icons .search-type-icon {
    cursor: pointer;
    font-weight: bold;
    width: calc(50% - 1em);
    display: inline-block;
    text-align: center;
}

.search-type-icons .search-type-icon:nth-child(1) {
    margin-right: 1em;
}

.search-type-icons .search-type-icon:nth-child(2) {
    margin-left: 1em;
}

.search-query svg {
    margin-right: 0.2em;
}

.search-type-icons .search-type-icon svg {
    margin-left: auto;
    margin-right: auto;
    font-size: 4em;
    display: block;
}

.search-query {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex-shrink: 1;
}

.search-query #search-term {
    flex-grow: 1;
}

.search-query #search-case-sensitive {
    margin-bottom: 0.4em;
}

#explore-user-stats {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

#explore-user-stats p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}