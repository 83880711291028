/** Special rules for the Write Entry page */

.entry-write form {
  width: 92%;
}

.entry-write form fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.entry-write-day form:not(:first-child) fieldset {
  border-top: 2px solid threedface;
  padding-top: 0.3em;
}

.entry-write-day form:not(:last-child) fieldset {
  border-bottom: 2px solid threedface;
  margin-bottom: 0.3em;
}

#entry-write-header svg.write-date-picker {
  color: var(--primary);
  cursor: pointer;
  margin-left: 0.1em;
}

#entry-write-header svg.write-date-picker,
#entry-write-header svg.loading-day {
  margin-top: 0.15em;
}

div.entry-on-day {
  white-space: break-spaces;
  margin-bottom: 0.75em;
}

#entry-write-header svg.loading-day,
svg.entry-on-day {
  color: var(--primary);
}

.no-line-wrap {
  display: inline-block;
  white-space: pre-line;
}

.entry-write-descriptor svg,
.entry-write input,
.entry-write-entry {
  font-size: larger;
  margin-top: 0.5em;
}

.entry-write-descriptor {
  width: 100%;
  overflow: hidden;
}

.entry-write svg.entry-type {
  color: var(--primary);
  cursor: pointer;
  margin-right: 0.5em;
  height: 1em;
}

.entry-write-descriptor input,
.entry-write-descriptor textarea {
  float: right;
  width: calc(100% - 2.5em);
  min-width: calc(100% - 2.5em);
}

#modification-conflict-warning div {
    font-weight: bold;
    padding: 0 0.5em;
}

#modification-conflict-warning div:first-of-type {
    float:left;
}

#modification-conflict-warning div:nth-last-of-type(2) {
    float:right;
}

#modification-conflict-warning div:last-of-type {
    clear:both;
}

/* entry summary */

.entry-write textarea {
  height: 6em;
  min-height: 6em;
  font-family: system-ui;
  line-height: 1.7em;
  margin-top: 0.7em;
}

.entry-write-entry {
  position: relative;
  margin-bottom: 0.5em;
}


.entry-write-entry textarea {
  min-width: calc(100% - 0.5em);
  height: 7em;
  min-height: 7em;
  display: block;
}

.power-user .entry-write-entry textarea {
  height: 40em;
}

.entry-write-entry .entry-write-status {
  position: absolute;
  bottom: 0.2em;
  right: 1.3em;
  pointer-events: none;
  font-size: small;
  font-style: italic;
}

.entry-write-day textarea,
.entry-write-day input {
  max-width: calc(100% - 0.5em);
}

.entry-write input[type="date"] {
  width: 10em;
  margin-right: 0.5em;
}

.entry-write-metadata,
.entry-write-timing {
  text-align: right;
}

.entry-write-timing .entry-timespan {
  margin-right: 0.4em;
}

.entry-write-timing .entry-timespan:not(input) {
    font-style: italic;
}

.entry-write-timing input,
.entry-write-metadata input {
  width: 6em;
}

.entry-write-metadata input[type="number"] {
  width: 3.3em;
}


/* Spans */
div.entry-span {
  padding-left: 1.5em;
}

div.entry-span .toggle {
  cursor: pointer;
  color: var(--primary);
}

div.entry-span .entry-span-children.entry-span-collapsed {
  display: none;
}

.entry-write-entry .entry-write-status svg,
div.entry-span > span svg {
  margin-left: 0.3em;
}

div.entry-span a {
  text-decoration: none;
}

div.entry-span p {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

div.entry-span .entry-span-dates {
  margin-top: 0;
  margin-left: 1.8em;
  font-style: italic;
  font-size: smaller;
}

/* Voice transcript */

.voice-transcript-block {
    text-align: right;
}

.voice-transcript-block span {
  cursor: pointer;
  transition: color 0.3s;
}

.voice-transcript-block span.clicked {
  color: var(--disabled)
}

.search-query .separator,
.voice-transcript-block .separator {
    display: inline-block;
    position: relative;
    top: 0.2em;
    border-radius: 1em;
    height: 1.2em;
    width: 0.2em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    background-color: var(--disabled);
}

/* Form-field status indicators */
fieldset textarea:focus,
fieldset input:focus {
  outline: 2px solid var(--primary-highlight);
}

fieldset textarea,
fieldset input {
  border: 1px solid var(--primary-highlight);
  background-color: var(--background-app);
}

fieldset.form-warning textarea,
fieldset.form-warning input {
  border: 2px outset var(--error);
}

fieldset.form-offline textarea,
fieldset.form-offline input {
  border: 2px dotted black;
}

fieldset.entry-refreshing textarea,
fieldset.entry-refreshing input,
fieldset.entry-waiting textarea,
fieldset.entry-waiting input {
  border: 1px dashed var(--primary-highlight);
}

fieldset.entry-refreshing textarea:focus,
fieldset.entry-refreshing input:focus,
fieldset.entry-waiting textarea:focus,
fieldset.entry-waiting input:focus {
  outline: 1px dashed var(--primary-highlight);
}

fieldset.entry-saving textarea,
fieldset.entry-saving input {
  border: 1px solid transparent;
  animation: linear-gradient-move 0.3s infinite linear;
  background:
    linear-gradient(90deg, var(--primary-highlight) 50%, transparent 0) repeat-x,
    linear-gradient(90deg, var(--primary-highlight) 50%, transparent 0) repeat-x,
    linear-gradient(0deg, var(--primary-highlight) 50%, transparent 0) repeat-y,
    linear-gradient(0deg, var(--primary-highlight) 50%, transparent 0) repeat-y,
    linear-gradient(90deg, var(--background-app) 100%, var(--background-app) 100%);
  background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px, 1px 1px;
  background-position: 0 0, 0 100%, 0 0, 100% 0, 0 100%;
}

fieldset.entry-saving textarea:focus,
fieldset.entry-saving input:focus {
  outline: 1px dashed var(--primary-highlight);
  border: 1px dashed var(--primary-highlight);
}

@keyframes linear-gradient-move {
  100% {
    background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
  }
}
