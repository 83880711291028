:root {
  --primary: #f50;
  --primary-highlight: #f90;
  --error: #d20000;
  --text: black;
  --disabled: #a9a9a9;
  --background-app: white;
  --background-page: #f8f8f8;
  --background-thumbnail: #e8e8e8;
  --background-highlighted: #d3d3d3;
}
