/** Special rules for the settings page */

.sync-logo {
  height: 1.3em;
  margin-top: 0.4em;
  margin-bottom: 0.6em;
}

.sync-logo img {
  height: 100%;
  vertical-align: top;
}

.sync-button {
  cursor: pointer;
}

.sync-input-rows div {
  font-style: italic;
}

.sync-input-rows input {
  width: 15em;
}

.sync-input-rows {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  text-align: right;
}


/** Special rules for the admin pages */

.settings-thumbnail-list .thumbnail {
  font-size: 1.2em;
}

.admin-thumbnail-list .thumbnail svg {
  font-size: 1.8em;
}

.settings-thumbnail-list .separator {
  display: inline-block;
  border-radius: 1em;
  height: 1.2em;
  width: 0.2em;
  margin-right: 0.65em;
  background-color: var(--disabled);
}

.settings-thumbnail-list svg.has-action:not(.loading) {
  cursor: pointer;
}

.settings-thumbnail-list svg.unknown {
  color: var(--disabled);
}

.settings-thumbnail-list svg.loading {
  color: grey;
}

.settings-thumbnail-list svg.refresh {
  font-size: 1.2em;
}

.settings-thumbnail-list svg.status {
  margin-right: 0.2em;
}

.settings-thumbnail-list svg.failed {
  color: var(--error);
  background-color: pink;
}

.settings-thumbnail-list svg.enabled,
svg.entry-write-status-saved,
svg.entry-write-status-restore {
  color: green;
}

a.disabled,
.disabled:not(.nav-bar-menu-toggle) svg,
.disabled svg.nav-bar-button-overlay,
.settings-thumbnail-list svg.disabled,
svg.entry-write-status-failed,
svg.entry-write-status-delete {
  color: var(--error);
}

.settings-thumbnail-list .note svg {
  color: var(--primary);
  font-size: 1em;
}

.settings-thumbnail-list .pre {
  font-family: monospace;
  white-space: pre-wrap;
  font-size: 0.9em;
}

.settings-thumbnail-list .clarification {
  display: inline-block;
  font-size: smaller;
  line-height: normal;
  margin-left: -0.3em;
  margin-right: 0.4em;
}

.settings-thumbnail-list h4:not(:first-child) {
  margin-top: 0.8em;
}

.thumbnail:hover:not(.thumbnail-static) {
  background-color: var(--background-highlighted);
  cursor: pointer;
}

.settings-thumbnail-list .sync-input-rows form svg {
  font-size: 1.2em;
  vertical-align: text-bottom;
  padding-left: 0.2em;
  cursor: pointer;
}

.settings-thumbnail-list div.fa-layers.fa-fw {
  margin-left: 0.9em;
  padding-bottom: 0.6em;
}

.settings-thumbnail-list .fa-layers-top-right.fa-play {
  left: 0.15em;
}
