/** Special rules for the Explore page */

.section-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5em;
}

.section-tab {
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.section-tab,
.search-type-icon {
    transition: color 0.5s;
}

.section-tab-selected {
    color: var(--primary);
}

.section-tab::after {
	content: '';
	display: block;
	margin: auto;
	height: 2px;
	width: 0;
	background: transparent;
	transition: width 0.5s ease, background-color 0.5s ease;
}

.section-tab:hover::after,
.section-tab-selected::after {
	width: 100%;
	background: var(--primary);
}