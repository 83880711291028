/* Navigation bar */

#nav-bar {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#ubiquitous-nav-bar {
  display: flex;
  justify-content: space-around;
}

#toggled-nav-bar-wrapper {
  display: flex;
  width: 100%;
}

#nav-and-notification-bar-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  pointer-events: none;
  width: 100%;
  max-width: calc(600px + 2 * 8px);
}

#nav-bar-contents {
  position: absolute;
  width: calc(100% - 2.2em);
  padding-left: 1.1em;
  padding-right: 1.1em;
  height: 100%;
}

.toggled-nav-bar {
  pointer-events: all;
  overflow-y: hidden;
  transition: max-height 0.6s;
  max-height: 0;
  display: flex;
  flex-flow: column;
  position: relative;
  bottom: -2px;
  margin-top: auto;
}

#nav-notification-bar {
  font-style: italic;
  text-align: center;
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;
  margin-top: 0;
  color: var(--primary);
}

#nav-notification-bar.nav-notification-bar-shown {
  max-height: 5em;
}

#nav-notification-bar-content {
  padding: 0.2em 8px;
}

#nav-notification-bar, #nav-notification-bar svg {
  font-size: medium;
}

.toggled-nav-bar.toggled {
  max-height: 100%;
}

.toggled-nav-bar > a:last-child > div {
  margin-bottom: 0.1em;
}

.nav-bar-button {
  margin: 0 5px;
  color: var(--disabled);
  transition: color 0.3s;
}

.toggled-nav-bar .nav-bar-button {
  margin-right: 0.11em;
  margin-top: -0.055em;
  margin-bottom: -0.055em;
}

.nav-bar-button-img {
  height: 100%;
  padding-top: 0;
  margin: 0;
  text-align: center;
}

.nav-bar-button-img img {
  height: 100%;
}

.nav-bar-button-text {
  padding-left: 0.3em;
  position: relative;
  bottom: 0.15em;
}

.nav-bar-button svg {
  width: 1em;
}

#nav-notification-bar svg {
  margin-right: 0.3em;
}

svg.nav-bar-button-overlay {
  position: absolute;
  left: 0;
  font-size: 0.5em;
  color: var(--primary);
}

svg.nav-bar-button-overlay.nav-bar-button-active {
  color: var(--primary-highlight);
}

.nav-bar-button >span {
  transition: color 0.5s;
}

.nav-bar-button:hover >span {
  color: var(--primary);
}

.nav-bar-button-logo svg.nav-bar-button-overlay {
  color: (--background-app);
  left: -0.06em;
  top: -0.15em;
}

.nav-bar-button-img-with-overlay img {
  opacity: 0.5;
}

.nav-bar-button-img-with-overlay svg.nav-bar-button-overlay {
  position: absolute;
  left: 0;
  font-size: 0.5em;
}

#user-nav-bar-toggle-icon svg.nav-bar-button-overlay {
  inset: 0 auto auto 0;
}

#menu-nav-bar-toggle-icon svg.nav-bar-button-overlay {
  inset: 0 0 auto auto;
}

#ubiquitous-nav-bar a {
  text-decoration: none;
}

#ubiquitous-nav-bar .nav-bar-button {
  text-align: center;
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

#ubiquitous-nav-bar .nav-bar-button:not(.nav-bar-button-img) {
  padding-top: 0.1em;
}

#ubiquitous-nav-bar .nav-bar-button-text {
  padding-left: 0;
  padding-top: 0.2em;
}

#ubiquitous-nav-bar .nav-bar-button svg {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#ubiquitous-nav-bar .nav-bar-button-img-failed {
  display: none;
}

#menu-nav-bar {
  left: 0;
  border-right: 1px solid var(--primary-highlight);
}

#user-nav-bar {
  margin-left: auto;
  border-left: 1px solid var(--primary-highlight);
}

#menu-nav-bar-toggle-icon {
  position: absolute;
  left: 0.1em;
}

.nav-bar-button-active {
  color: var(--primary);
}

.nav-bar-button-logo {
  color: var(--primary);
  margin-top: 0.2em;
  padding-top: 0.2em;
  margin-left: auto;
  margin-right: auto;
}

#nav-bar-user,
#nav-bar-user img {
  float: right;
  height: 100%;
}

.nav-bar-menu-toggle,
.logout-icon {
  cursor: pointer;
}

.nav-bar-menu-toggle:not(.nav-bar-button-active) {
  color: var(--text);
}

.logout-icon {
  color: #d9a9a9 !important;
}

.tip .logout-icon {
  font-size: 1em;
  margin-right: 0.15em;
  margin-left: 0.15em;
}

.page-link-shortcut {
  text-decoration: underline;
  text-decoration-thickness: 5%;
}
